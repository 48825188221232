import axios from 'axios';
import authHeader from './authHeader';
import Config from './Config';
import { isEmpty } from 'lodash';

// axios.defaults.params = {
//   company_id: 1,
//   // Add more default parameters if needed
// };

const getJobField = () => {
  const data = {
    codename: 'JOB_CREATION_COMPLETE_DATA',
  };

  return axios.post(
    Config.API_URL + 'api/v1/settings/job-creation-fields-to-show-or-not/',
    data,
    {
      headers: authHeader(),
    },
  );
};

const getSearchValues = (searchValue, url, active = true) => {
  return axios.get(url + `?is_active=${active}&search=${searchValue}`, {
    headers: authHeader(),
  });
};

const getOptions = (url) => {
  return axios.get(url, {
    headers: authHeader(),
  });
};

const getSearchOptions = (value, url) => {
  var Api_url = url + '?search=' + value;
  return axios.get(Api_url, {
    headers: authHeader(),
  });
};

const getJobsList = (params) => {
  return axios.get(Config.API_URL + 'api/v1/jobs/job-listing/', {
    params,
    headers: authHeader(),
  });
};

const createBasicInfo = (data, params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  if (params?.job_id) {
    return axios.patch(
      Config.API_URL + `api/v1/jobs/job-initial/${params?.job_id}/`,
      data,
      config,
    );
  } else {
    return axios.post(
      Config.API_URL + 'api/v1/jobs/job-initial/',
      data,
      config,
    );
  }
};

const getPrescreeningValue = () => {
  return axios.get(
    Config.API_URL + 'api/v1/jobs/prescreen-inerviewtype-fields/',
    {
      headers: authHeader(),
    },
  );
};

const saveHiringStage = (data, params) => {
  const config = {
    params,
    headers: authHeader(),
  };

  return axios.post(
    Config.API_URL + 'api/v1/jobs/job-hiring-stages/',
    data,
    config,
  );
};

const getEditJob = (nId) => {
  return axios.get(
    Config.API_URL + 'api/v1/jobs/job-initial/' + nId + '/',
    {
      headers: authHeader(),
    },
  );
};

const getInterviwer = (value, id) => {
  return axios.get(
    Config.API_URL +
      'api/v1/accounts/list-users-based-on-type/?user_type=REV&jobid=' +
      id +
      '&search=' +
      value,
    {
      headers: authHeader(),
    },
  );
};

const getAlluser = (value) => {
  return axios.get(
    Config.API_URL +
      'api/v1/accounts/list-users-based-on-type/?search=' +
      value,
    {
      headers: authHeader(),
    },
  );
};

const getRecuiter = (value, id) => {
  return axios.get(
    Config.API_URL +
      'api/v1/accounts/list-users-based-on-type/?user_type=REC&jobid=' +
      id +
      '&search=' +
      value,

    {
      headers: authHeader(),
    },
  );
};

const publishJobs = (data, params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.post(
    Config.API_URL + 'api/v1/jobs/job-final-status/',
    data,
    config,
  );
};

const hiringTeam = (data, params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.post(
    Config.API_URL + 'api/v1/jobs/job-hiring-team/',
    data,
    config,
  );
};

const getJobInfoData = (nId) => {
  return axios.get(
    Config.API_URL + 'api/v1/jobs/job-details/' + nId + '/',

    {
      headers: authHeader(),
    },
  );
};

const getViewMoreData = (nId) => {
  return axios.get(
    Config.API_URL + 'api/v1/jobs/job-details-view-more/' + nId + '/',

    {
      headers: authHeader(),
    },
  );
};

const getReferences = () => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/jobs/job-creation-common-data/`,
    config,
  );
};

const deleteJob = (nId) => {
  return axios.delete(
    Config.API_URL + 'api/v1/jobs/job-initial/' + nId + '/',
    {
      headers: authHeader(),
    },
  );
};

const getHiringTeam = (jobId) => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    Config.API_URL + 'api/v1/jobs/job-hiring-team/?job=' + jobId,
    config,
  );
};

const getHiringStage = (jobId) => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    Config.API_URL + 'api/v1/jobs/job-hiring-stages/?job=' + jobId,
    config,
  );
};

const getPublish = (statusId) => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/jobs/job-final-status/${statusId}/`,
    config,
  );
};

const updateBasicInfo = (data, nId, params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.put(
    Config.API_URL + 'api/v1/jobs/job-initial/' + nId + '/',
    data,
    config,
  );
};

const updatePublishJobs = (body, id) => {
  const config = {
    headers: authHeader(),
  };
  return axios.patch(
    `${Config.API_URL}api/v1/jobs/job-final-status/${id}/`,
    body,
    config,
  );
};

const UpdateHiringStage = (data, id, params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.patch(
    Config.API_URL + 'api/v1/jobs/job-hiring-stages/' + id + '/',
    data,
    config,
  );
};

const getJobInterviewLevels = (params) => {
  return axios.get(
    Config.API_URL + 'api/v1/jobs/interview-levels-by-job/',
    {
      params,
      headers: authHeader(),
    },
  );
};
const getCheckInterviewLevels = (params) => {
  return axios.get(Config.API_URL + 'api/v1/jobs/check-interview-level/', {
    params,
    headers: authHeader(),
  });
};
const getJobSchedules = (params, filedData) => {
  var test = filedData ? filedData : '';
  return axios.get(
    `${Config.API_URL}api/v1/jobs/interview-schedule-date/?${test}`,
    {
      params,
      headers: authHeader(),
    },
  );
};

const getJobDetailCandidateMetadata = (id) => {
  const config = {
    headers: authHeader(),
  };

  return axios.get(
    `${Config.API_URL}api/v1/jobs/job-candidates-view-more/${id}/`,
    config,
  );
};

const scheduleJobInterview = (data, params) => {
  const config = {
    headers: authHeader(),
  };
  if (!isEmpty(params)) {
    config['params'] = params;
  }
  return axios.post(
    Config.API_URL + 'api/v1/interview/schedule/',
    data,
    config,
  );
};

const buildResumeForCandidate = (body, params) => {
  const config = {
    headers: authHeader(),
  };
  if (!isEmpty(params)) {
    config['params'] = params;
  }

  return axios.post(
    `${Config.API_URL}api/v1/jobs/build-resume/`,
    body,
    config,
  );
};

const getJobCandidatesList = (params, filterValue) => {
  var filter = filterValue ? filterValue : '';
  return axios.get(
    Config.API_URL + `api/v1/candidates/job-candidates-list/?${filter}`,
    {
      params,
      headers: authHeader(),
    },
  );
};

const getWebsocketInformation = (params) => {
  return axios.get(
    Config.API_URL +
      'api/v1/interview/get-websocket-info-in-schedule-interview',
    {
      params,
      headers: authHeader(),
    },
  );
};

const updateConsultancyInterviewSchedule = (body, params, id) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.put(
    `${Config.API_URL}api/v1/jobs/consultancy-live-schedule/${id}/`,
    body,
    config,
  );
};

const getAutoFillJobData = (value) => {
  return axios.get(
    Config.API_URL +
      'api/v1/jobs/job-listing/?status=JOB_DATA_SUGGESTION&limit=7&offset=0is_limited_search=True&search=' +
      value,
    {
      headers: authHeader(),
    },
  );
};

const getJobStatusList = () => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(`${Config.API_URL}api/v1/jobs/job-count/`, config);
};

const getJobListings = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(`${Config.API_URL}api/v1/jobs/job-listing/`, config);
};

export const getAwaitingQuestionnaireJobListings = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/jobs/questionnaire-awaiting-jobs/`,
    config,
  );
};

/* 
  For fetching job activities
*/
const getJobActivities = (params) => {
  return axios.get(Config.API_URL + 'api/v1/jobs/candidate-activity/', {
    params,
    headers: authHeader(),
  });
};

const getQuestionaire = () => {
  return axios.get(
    Config.API_URL + 'api/v1/interview/list-questionaires/',
    {
      headers: authHeader(),
    },
  );
};

const fetchClients = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/company/clients/?is_consultant_or_client=CLIENT`,
    config,
  );
};

const fetchConsultancies = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/company/clients/?is_consultant_or_client=CONSULTANT`,
    config,
  );
};

const fetchScheduleRequests = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/jobs/schedule-consultancy-candidate/`,
    config,
  );
};

/* 
  For fetching score card lst based on interview levels
*/
const getInterviewLevelScoreCardList = (params, filter) => {
  var filterValue = filter ? filter : '';
  // return axios.get(Config.API_URL + "api/v1/jobs/interview-status-by-level" + filterValue, {
  //   params,
  //   headers: authHeader(),
  // });
  return axios.get(
    `${Config.API_URL}api/v1/jobs/interview-status-by-level/?${filterValue}`,
    {
      params,
      headers: authHeader(),
    },
  );
};

/* 
  For fetching pre screen score card list 
*/
const getPreScreenScoreCardListing = (params) => {
  // return axios.get(Config.API_URL + "api/v1/jobs/job-prescreen-list/", {
  //   params,
  //   headers: authHeader(),
  // });
  // var filterValue = filter ? filter : '';
  return axios.get(
    `${Config.API_URL}api/v1/jobs/job-prescreen-list/`,
    {
      params,
      headers: authHeader(),
    },
  );
};
/* 
  For fetching pre screen score card answers
*/
const getPreScreenScoreCardAnswers = (params) => {
  return axios.get(Config.API_URL + 'api/v1/jobs/get-prescreen-answers/', {
    params,
    headers: authHeader(),
  });
};

const getOfferLetterTemplates = () => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    Config.API_URL + 'api/v1/jobs/offerletter-templates-list/',
    config,
  );
};

const sendCandidateOfferLetter = (data) => {
  const config = {
    headers: authHeader(),
  };
  return axios.post(
    Config.API_URL + 'api/v1/jobs/send-offer-letter/',
    data,
    config,
  );
};

const getRejectReasonTypes = () => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    Config.API_URL + 'api/v1/jobs/offer-reject-reason/',
    config,
  );
};

const rejectCandidateOffer = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.put(
    Config.API_URL + 'api/v1/jobs/offer-reject/',
    {},
    config,
  );
};

const getOfferedOrRejectList = (params, filter) => {
  var filter_value = filter ? filter : '';
  return axios.get(
    Config.API_URL +
      `api/v1/jobs/offered-and-rejected-list/?${filter_value}`,
    {
      params,
      headers: authHeader(),
    },
  );
};

const getActivityData = (params) => {
  return axios.get(
    Config.API_URL +
      `api/v1/jobs/candidate-activity/?candidate=${params.candidateId}&job_id=${params.job_id}`,
    {
      headers: authHeader(),
    },
  );
};

const getJobParam = (params) => {
  return axios.get(
    Config.API_URL +
      `api/v1/jobs/resume_weightage/?job_id=${params.job_id}`,
    {
      headers: authHeader(),
    },
  );
};

const getStatusReasonTypes = () => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    Config.API_URL + 'api/v1/jobs/status-after-offer/',
    config,
  );
};

const updateParamScore = (params) => {
  const config = {
    headers: authHeader(),
  };
  const data = {
    job_id: params.job_id,
    resume_weightage: {
      data: params.resume_weightage,
    },
  };
  return axios.put(
    Config.API_URL + 'api/v1/jobs/resume_weightage/',
    data,
    config,
  );
};

const sendStatusUpadte = (params) => {
  const config = {
    headers: authHeader(),
  };
  return axios.put(
    Config.API_URL +
      `api/v1/jobs/decline-or-hire-candidate/${params.candidate_Id}/`,
    params.updatedData,
    config,
  );
};

const sendNoShowStatusUpadte = (data) => {
  const config = {
    headers: authHeader(),
  };
  return axios.post(
    Config.API_URL + 'api/v1/candidates/noshow-status-update/',
    data,
    config,
  );
};

const createJobActivityLogId = (body) => {
  const config = {
    headers: authHeader(),
  };
  return axios.post(
    Config.API_URL + `api/v1/logs/job-activity/`,
    body,
    config,
  );
};

const addNewMultiSearchValue = (searchData, url) => {
  return axios.post(url, searchData, {
    headers: authHeader(),
  });
};
const generateAIJobDescription = (payload) => {
  return axios.post(
    Config.API_URL + `api/v1/jobs/generate-job-descriptions/`,
    payload,
    {
      headers: authHeader(),
    },
  );
};

const getPermession = () => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    Config.API_URL + 'api/v1/jobs/check-job-creation-limit/',
    config,
  );
};

const getJobActivityDetails = (jobId) => {
  return axios.get(
    Config.API_URL + `api/v1/logs/job-activity/?job=${jobId}`,
    {
      headers: authHeader(),
    },
  );
};

const sendLog = (payload) => {
  return axios.post(
    Config.API_URL + `api/v1/jobs/generate-job-descriptions/`,
    payload,
    {
      headers: authHeader(),
    },
  );
};

const updateJobGridColumn = (data) => {
  const config = {
    headers: authHeader(),
  };
  return axios.put(
    Config.API_URL +
      'api/v1/settings/custom-table-settings/?is_column_settings=True',
    data,
    config,
  );
};

const getInterviewLevels = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/jobs/list-all-jobs-interview-levels/`,
    config,
  );
};

const jobService = {
  getJobField,
  getSearchValues,
  getOptions,
  getJobsList,
  createBasicInfo,
  getPrescreeningValue,
  saveHiringStage,
  getEditJob,
  getInterviwer,
  getRecuiter,
  getAlluser,
  getSearchOptions,
  publishJobs,
  getJobDetailCandidateMetadata,
  getJobListings,
  hiringTeam,
  getJobInfoData,
  getViewMoreData,
  getJobStatusList,
  deleteJob,
  getReferences,
  getHiringTeam,
  getHiringStage,
  getPublish,
  updateBasicInfo,
  updatePublishJobs,
  UpdateHiringStage,
  getJobSchedules,
  getJobInterviewLevels,
  scheduleJobInterview,
  buildResumeForCandidate,
  getJobCandidatesList,
  updateConsultancyInterviewSchedule,
  getJobActivities,
  getQuestionaire,
  fetchClients,
  fetchConsultancies,
  fetchScheduleRequests,
  createJobActivityLogId,
  getInterviewLevelScoreCardList,
  getPreScreenScoreCardListing,
  getPreScreenScoreCardAnswers,
  getWebsocketInformation,
  getAutoFillJobData,
  getOfferLetterTemplates,
  sendCandidateOfferLetter,
  getRejectReasonTypes,
  rejectCandidateOffer,
  getOfferedOrRejectList,
  getActivityData,
  getJobParam,
  getStatusReasonTypes,
  updateParamScore,
  sendStatusUpadte,
  addNewMultiSearchValue,
  generateAIJobDescription,
  getPermession,
  getJobActivityDetails,
  sendLog,
  getCheckInterviewLevels,
  sendNoShowStatusUpadte,
  updateJobGridColumn,
  getInterviewLevels,
};
export default jobService;
