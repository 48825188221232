import Button from 'Components/CommonComponents/Button/Button';
import Tabs from 'Components/CommonComponents/Tabs/Tabs';
import React, { useEffect } from 'react';
import { useMemo } from 'react';
import { memo } from 'react';
import iconLiveness from 'Assets/icons/iconLiveness.svg';
import emotionImage from 'Assets/images/emotionImage.svg';
import { Modal, Spin, Tooltip, message } from 'antd';
import iconBulb from 'Assets/icons/iconBulb.svg';
import iconInternetSpeed from 'Assets/icons/iconInternetSpeed.svg';
import iconVideo from 'Assets/icons/iconVideo.svg';
import iconMic from 'Assets/icons/iconMic.svg';
import iconDevice from 'Assets/icons/iconDevice.svg';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useGetAiAssessmentQuestionAnswers,
  useGetInfractionImages,
  useSubmitInterviewerFeedback,
} from 'ServiceHooks/interviewHooks';
import { isEmpty } from 'lodash';
import { DEFAULT_ERROR_MESSAGE } from 'Utilities/constants';
import 'Styles/Annotations.scss';
import Empty from 'Components/CommonComponents/Empty/Empty';
import { formatTime, reactQueryLoadingHelper } from 'Utilities/utils';
// import { useForm } from 'react-hook-form';
// import RadioGroup from 'Components/CommonComponents/ReactHooKForm/RadioGroup/RadioGroup';
// import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
// import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
// import TextArea from 'Components/CommonComponents/ReactHooKForm/TextArea/TextArea';
import iconMiniPieChart from 'Assets/icons/iconMiniPieChart.svg';
import { useForm } from 'react-hook-form';
import RadioGroup from 'Components/CommonComponents/ReactHooKForm/RadioGroup/RadioGroup';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import TextArea from 'Components/CommonComponents/ReactHooKForm/TextArea/TextArea';
import { toLowercaseAndReplaceSpaces } from '../../../Utilities/utils';
import face from './../../../Assets/images/face.svg';
import tabSwitch from './../../../Assets/images/tabsw.png';

// ---------- Starts :: chart related ----------

const COLORS = ['#FFBA6A', '#17A0FB'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
// ---------- Ends :: chart related ----------

const QUESTION_DETAILS_TYPE = {
  REFRACTION_IMAGE_FETCH: 'REFRACTION_IMAGE_FETCH',
  SUBMIT_INTERVIEW_FEEDBACK_FORM: 'SUBMIT_INTERVIEW_FEEDBACK_FORM',
};

const ANSWER_OPTIONS = [
  { label: 'Correct', value: 1 },
  { label: 'Incorrect', value: 2 },
];

const EMOTION_OPTIONS = [
  // { value: 'NOT_DETECTED', label: <p> Not Detected </p>, smileyValue: 'false' },
  { value: 'SMILE', label: <p> Smile &#128522; </p>, smileyValue: '&#128522' },
  { value: 'CRY', label: <p> Cry &#128546; </p>, smileyValue: '&#128546' },
  { value: 'ANGRY', label: <p> Angry &#128545; </p>, smileyValue: '&#128545' },
  {
    value: 'NEUTRAL',
    label: <p> Neutral &#128528; </p>,
    smileyValue: '&#128528',
  },
  {
    value: 'SURPRISED',
    label: <p> Surprised &#128558; </p>,
    smileyValue: '&#128558',
  },
  {
    value: 'FEARFUL',
    label: <p> Fearful &#128552; </p>,
    smileyValue: '&#128552',
  },
  {
    value: 'DISGUSTED',
    label: <p> Disgusted &#129314; </p>,
    smileyValue: '&#129314',
  },
];

const CORRECT_INCORRECT_TYPES = {
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT',
};
const DEFAULT_INTERVIEW_FEEDBACK_FORM_VALUES = {
  is_correct_answer: '',
  score: '',
  emotions: '',
  comments: '',
};
const Annotations = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();

  const mode = props?.mode;
  const method = props?.interviewMethod;
  const isModal = mode === 'ACTIVITY_MODAL';

  const { handleSubmit, setValue, control, reset } = useForm({
    defaultValues: DEFAULT_INTERVIEW_FEEDBACK_FORM_VALUES,
    mode: 'all',
    
  });
  const [questionDetails, setQuestionDetails] = useState(false);
  const [TabInfractionImgUrl, setTabInfractionImgUrl] = useState();
  const [isInterviewFeedbackModalOpen, setIsInterviewFeedbackModalOpen] =
    useState(false);
  const [isInfractionModalOpen, setIsInfractionsModalOpen] = useState(false);
  const interviewerFeedbackSubmit = (values) => {
    const selectedEmotion = EMOTION_OPTIONS.find(
      (item) => item.value === values.emotions.value
    );
    if (!isEmpty(selectedEmotion)) {
      const params = {
        ...values,
        is_correct_answer:
          values.is_correct_answer === 1
            ? CORRECT_INCORRECT_TYPES.CORRECT
            : CORRECT_INCORRECT_TYPES.INCORRECT,
        emotions: selectedEmotion.smileyValue,
        urlParams: {
          interviewId,
          interviewer_evaluation_feedback:
            questionDetails?.interviewer_feedback?.id,
          questionId: questionDetails?.id,
        },
      };
      submitInterviewFeedback(params);
    }
  };

  const interviewId = isModal 
    ? props?.interviewId 
    : location?.state?.interviewId;
  const {
    data: aiAssessmentQuestionAnswers,
    status: aiAssessmentQuestionAnswersStatus,
    refetch: refetchAiAssessmentQuestionAnswers,
  } = useGetAiAssessmentQuestionAnswers({
    params: {
      interviewId: interviewId,
    },
    enabled: !!interviewId,
  });

  const {
    data: infractionImages,
    status: infractionImagesStatus,
    refetch: refetchInfractionImages,
  } = useGetInfractionImages({
    enabled: false,
    params: {
      interviewId,
      objectId: questionDetails?.question_answer?.object_id,
    },
  });

  const { mutate: submitInterviewFeedback } = useSubmitInterviewerFeedback({
    onSuccess: () => {
      messageApi.open({
        type: 'success',
        content: 'Interview feedback submitted',
      });
      setQuestionDetails(null);
      setIsInterviewFeedbackModalOpen(false);
      refetchAiAssessmentQuestionAnswers();
    },
    onError: () => {
      messageApi.open({
        type: 'error',
        content: DEFAULT_ERROR_MESSAGE,
      });
    },
  });

  // fetching refraction images
  useEffect(() => {
    if (
      !isEmpty(questionDetails) &&
      questionDetails?.for === QUESTION_DETAILS_TYPE.REFRACTION_IMAGE_FETCH
    ) {
      setIsInfractionsModalOpen(true);
      refetchInfractionImages();
    }
  }, [questionDetails, refetchInfractionImages]);

  const items = useMemo(() => {
    const interviewData = aiAssessmentQuestionAnswers?.results || [];
    if (isEmpty(interviewData)) return [];
    return interviewData.map((questionParent, i) => {
      const number = i + 1;
      const questionItem = questionParent?.question_answer;
      const interviewFeedback = questionItem?.interviewer_feedback;
      const deviceDetails = questionItem?.infraction;
      const isSingleChoice = questionItem?.question_type === 'OPTIONS';
      const isMultiChoice = questionItem?.question_type === 'MULTI_CHOICE';
      const isEssay = questionItem?.question_type === 'CHAR';

      // one single loop for pupating list & answers
      const question = questionItem?.question;
      let answerToShow = [];
      const questionChoices = [];
      // let hasMultipleAnswers = false;
      if (isSingleChoice) {
        const answerArray = [];
        const mcqOptions = questionItem?.multi_options || [];
        mcqOptions.forEach((item, i) => {
          if (questionItem?.multi_option_answers?.includes(item.id)) {
            answerArray.push(item.option);
          }
          questionChoices.push(<li>{item.option}</li>);
        });
        answerToShow = answerArray.join(' ,');
      } else if (isMultiChoice) {
        const answerArray = [];
        const mcqOptions = questionItem?.multi_options || [];
        mcqOptions.forEach((item, i) => {
          questionChoices.push(<li>{item.option}</li>);
          if (questionItem?.multi_option_answers?.includes(item.id)) {
            answerArray.push(item.option);
          }
        });
        answerToShow = answerArray.join(', ');
        // hasMultipleAnswers = questionItem?.multi_option_answers?.length > 0;
      } else if (isEssay) {
        answerToShow = questionItem.text_answer;
      }
      const isLongAnswer = answerToShow?.length > 50;
      const data = [
        { name: 'Infraction', value: questionItem.infractions },
        { name: 'Non Infraction', value: 100 - questionItem.infractions },
      ];
      if (isEmpty(answerToShow)) answerToShow = 'No answer';
      const isCorrectAsPerAiFeedBack = questionParent?.is_correct_answer;
      const isInterviewer = isModal
        ? false
        : location?.state?.selectedData?.has_review_permission;

      function removeTags(str) {
        if (str === null || str === '') return false;
        else str = str.toString();
        return str.replace(/(<([^>]+)>)/gi, '');
      }

      return {
        label: `Qn ${number}`,
        key: questionParent.id,
        children: (
          <div className="h-full pr-[24px]">
            <div className="questionAnswerContent">
              <div className="questionAnswerUpper my-4">
                <div className="questionData flex flex-wrap -mx-3">
                  <div className={`${[method, location?.state?.selectedData?.interview_method].includes('AI_VIDEO')? 'md:w-2/3 w-full': 'w-full'} px-3`}>
                    <h1 className="font-semibold">{question}</h1>
                    {!isEssay && (
                      <ol
                        className="orderedAlphaList mt-3"
                        aria-label="Question list"
                      >
                        {questionChoices}
                      </ol>
                    )}
                    <Tabs
                      items={[
                        {
                          label: 'Answered text',
                          key: 1,
                          children: (
                            <div>
                              <p
                                className={`${
                                  isLongAnswer ? 'sm:mb-4' : 'sm:mb-0'
                                } whitespace-pre-wrap`}
                              >
                                {/* {answerToShow} */}
                                {
                                  removeTags(answerToShow)
                                }
                              </p>
                            </div>
                          ),
                        },
                        // {
                        //   label : 'Reference Answer',
                        //   key: 2,
                        //   children : (
                        //     <div>
                        //        Cing elit. Quibusdam quam nam, illum molestiae odio inventore sed rerum veritatis cumque corrupti sunt, delectus a exercitationem omnis quaerat deserunt in et esse!
                        //     </div>
                        //   )
                        // },
                      ]}
                    />
                  </div>
                  <div className="w-full md:w-1/3 px-3">
                      
                    {
                      [method, location?.state?.selectedData?.interview_method]
                        .includes('AI_VIDEO') && (

                        <div className="border rounded-md">
                          {questionParent.answer_video ? (
                            <video className="w-full h-full" controls>
                              <source
                                src={questionParent.answer_video}
                                type="video/mp4"
                              />
                              <source
                                src={questionParent.answer_video}
                                type="video/ogg"
                              />
                          Your browser does not support the video tag.
                            </video>
                          ) : (
                            <Empty
                              description={
                                <p className="text-xs">
                                  <span className="font-semibold">
                                  It appears that the candidate chose to skip the question{' '}
                                  </span>
                                </p>
                              }
                            />
                          )}
                        </div>
                      )
                    }
                        
                  </div>
                        
                </div>
                <div className="mt-4 flex flex-wrap flex-col md:flex-row items-start md:items-center">
                  <span className="mr-3"> AI Feedback : </span>
                  {isCorrectAsPerAiFeedBack ? (
                    <p
                      className={`
                        rounded-full px-4 mr-4 flex items-center py-1 text-white mb-4 md:mb-0
                        ${
                    isCorrectAsPerAiFeedBack
                      ? 'bg-successMain'
                      : 'bg-errorMain'
                    }
                      `}
                    >
                      Cleared
                    </p>
                  ) : (
                    <p
                      className={`
                        rounded-full px-4 mr-4 flex items-center py-1 text-white mb-4 md:mb-0
                        ${
                    isCorrectAsPerAiFeedBack
                      ? 'bg-successMain'
                      : 'bg-errorMain'
                    }
                      `}
                    >
                      Failed
                    </p>
                  )}
                  {!isEmpty(questionParent.interviewer_feedback) &&
                    (isEmpty(interviewFeedback) && !interviewFeedback?.score ? (
                      <Button
                        isSecondary
                        onClick={() => {
                          const details = questionParent?.interviewer_feedback;
                          const currentAnswerLowerCased =
                            toLowercaseAndReplaceSpaces(
                              details?.is_correct_answer || ''
                            );
                          const answer = ANSWER_OPTIONS.find(
                            (item) =>
                              toLowercaseAndReplaceSpaces(item.label) ===
                              currentAnswerLowerCased
                          );
                          const currentEmotionLowerCased =
                            toLowercaseAndReplaceSpaces(
                              details?.emotions || ''
                            );
                          const emotion = EMOTION_OPTIONS.find(
                            (item) =>
                              toLowercaseAndReplaceSpaces(item.smileyValue) ===
                              currentEmotionLowerCased
                          );
                          const valuesToReset = {
                            is_correct_answer: answer?.value,
                            score: details?.score,
                            emotions: {
                              value: emotion?.value,
                            },
                            comments: details?.comments,
                          };
                          reset(
                            valuesToReset ||
                              DEFAULT_INTERVIEW_FEEDBACK_FORM_VALUES
                          );
                          setQuestionDetails(questionParent);
                          setIsInterviewFeedbackModalOpen(true);
                        }}
                        className=" mb-4 md:mb-0"
                        disabled={isInterviewer === false}
                      >
                        Interview feedback
                      </Button>
                    ) : (
                      <p className="px-3 border-dashed border rounded-md flex items-center md:ml-4">
                        <img
                          src={iconMiniPieChart}
                          alt="pie chart"
                          className="h-full w-9"
                        />
                        <span className="text-[#535353] dark:text-white">
                          <span className="font-semibold">
                            {interviewFeedback.score}
                          </span>{' '}
                          Interviewer Score
                        </span>
                      </p>
                    ))}
                </div>
              </div>
              <div className="questionAnswerLower">
                <div className="h-1 my-spacing3 border-b" />
                <div class="flex flex-wrap -mx-3 mb-spacing3">
                  <div class="w-full md:w-2/4 xl:w-1/4 mb-4 xl:mb-0 px-3 flex items-center justify-center">

                    {
                      [method, location?.state?.selectedData?.interview_method]
                        .includes('AI_VIDEO') && (
                        <div className="w-full h-full bg-[white] dark:bg-transparent p-4">

                          <div className="flex justify-between items-center">
                            {/* <p className="font-semibold">Infractions</p> */}
    
                            {questionItem?.infractions !== 0 ?(
                                <Button
                                  isSecondary
                                  className="b-none"
                                  onClick={() => {
                                    setQuestionDetails({
                                      ...questionParent,
                                      for: QUESTION_DETAILS_TYPE.REFRACTION_IMAGE_FETCH,
                                    });
                                  }}
                                >
                                View
                                </Button>
                              ) : null}
                          </div>
    
                          <div className="chartWrapper w-full h-[250px]">
    
                            <div class="bg-[white] dark:bg-transparent rounded-md p-5 dark:border h-full w-full flex items-center justify-center flex-col">
                              <div className="h-24 flex justify-center">
                                <img
                                  src={questionParent.is_tab_switch ? tabSwitch : face}
                                  alt="liveness of interview"
                                  className="h-full w-24"
                                />
                              </div>
                              <p className="text-[#0C2556] dark:text-white font-semibold text-heading1 text-center mt-4">
                                Infractions
                              </p>
                              <p className="text-[#7B9DE0] text-body3 text-center">
                                {questionItem?.infractions !== 0 ||
                                questionParent.is_tab_switch
                                  ? 'Infractions Detected'
                                  : 'Infractions Not Detected'}
                              </p>

                              <p className='text-[#0C2556] dark:text-white font-semibold text-heading1 text-center mt-4'>
                                {
                                  questionParent.is_tab_switch
                                  ?  <span> Tab Switch Time : {" "}  
                                     {formatTime(questionParent.tab_switched_time)}
                                  </span> : null
                                  
                                }
                              </p>
                            </div>
                          </div>
    
                        </div>
                      )
                    }

                    {
                      [method, location?.state?.selectedData?.interview_method]
                        .includes('AI_INTERVIEW_WITHOUT_VIDEO') &&(
                        <div className="w-full h-full bg-[white] dark:bg-transparent p-4">

                          <div className="flex justify-between items-center">
                           
    
                          </div>
    
                          <div className="chartWrapper w-full h-[250px]">
    
                            <div class="bg-[white] dark:bg-transparent rounded-md p-5 dark:border h-full w-full flex items-center justify-center flex-col">
                              <div className="h-24 flex justify-center">
                                <img
                                  src={tabSwitch}
                                  alt="liveness of interview"
                                  className="h-full object-scale-down"
                                />
                              </div>
                              <p className="text-[#0C2556] dark:text-white font-semibold text-heading1 text-center mt-4">
                                Infractions
                              </p>
                              <p className="text-[#7B9DE0] text-body3 text-center">
                                { questionParent.is_tab_switch
                                  ? 'Infractions Detected'
                                  : 'Infractions Not Detected'}
                              </p>
                              <p className='text-[#0C2556] dark:text-white font-semibold text-heading1 text-center mt-4'>
                                {
                                  questionParent.is_tab_switch
                                  ?  <span> Tab Switch Time : {" "}  
                                     {formatTime(questionParent.tab_switched_time)}
                                  </span> : null
                                  
                                }
                              </p>
                            </div>

                   
                          </div>
    
                        </div>
                      )
                    }
                  </div>

                  {
                    [method, location?.state?.selectedData?.interview_method]
                      .includes('AI_VIDEO') && (
                      <div class="w-full md:w-2/4 xl:w-1/4 mb-4 xl:mb-0 px-3 ">
                        <div class="bg-[white] dark:bg-transparent rounded-md p-5 dark:border h-full w-full flex items-center justify-center flex-col">
                          <div className="h-24 flex justify-center">
                            <img
                              src={iconLiveness}
                              alt="liveness of interview"
                              className="h-full w-24"
                            />
                          </div>
                          <p className="text-[#0C2556] dark:text-white font-semibold text-heading1 text-center mt-4">
                            Liveness
                          </p>
                          <p className="text-[#7B9DE0] text-body3 text-center">
                            {questionItem?.liveness
                              ? 'Candidate was not that live.'
                              : 'Candidate is Active'}
                          </p>
                        </div>
                      </div>
                    )
                  }

                  {
                    [method, location?.state?.selectedData?.interview_method]
                      .includes('AI_VIDEO') && (
                      <div class="w-full md:w-2/4 xl:w-1/4 mb-4 xl:mb-0 px-3 flex items-center justify-center">
                        <div class="bg-[white] dark:bg-transparent rounded-md p-5 dark:border h-full w-full flex items-center justify-center flex-col">
                          <div className="h-24 flex justify-center">
                            <img
                              src={emotionImage}
                              alt="emotion of interview"
                              className="h-full w-24"
                            />
                          </div>
                          <p className="text-[#0C2556] dark:text-white font-semibold text-heading1 text-center mt-4">
                          Emotion
                          </p>
                          <p className="text-[#7B9DE0] text-body3 text-center">
                          Neutral
                          </p>
                        </div>
                      </div>
                    )
                  }

                  {
                    [method, location?.state?.selectedData?.interview_method]
                      .includes('AI_VIDEO') &&(
                      <div class="w-full md:w-2/4 xl:w-1/4 mb-4 xl:mb-0 px-3 flex items-center justify-center">
                        <ul className="bg-[white] dark:bg-transparent rounded-md h-full w-full p-4">
                          <li className="flex items-center mb-4">
                            <div className="icon rounded-full dark:bg-[#d3d3d3] bg-[#E7EDFC] h-12 w-12 flex items-center justify-center">
                              <img
                                src={iconDevice}
                                alt="device"
                                className="h-auto w-4"
                              />
                            </div>
                            <div class="meta pl-4 ">
                              <p className="text-[#A3AED0] font-normal">Device</p>
                              <p className="dark:text-white text-[#2B3674] font-semibold">
                                {deviceDetails.device_info || 'No device info'}
                              </p>
                            </div>
                          </li>
                          <li className="flex items-center mb-4">
                            <div className="icon rounded-full dark:bg-[#d3d3d3] bg-[#E7EDFC] h-12 w-12 flex items-center justify-center">
                              <img
                                src={iconMic}
                                alt="device"
                                className="h-auto w-4"
                              />
                            </div>
                            <div class="meta pl-4 ">
                              <p className="text-[#A3AED0] font-normal">Mic</p>
                              <p className="dark:text-white  text-[#2B3674] font-semibold">
                                {' '}
                                {deviceDetails.device_info
                                  ? 'Working Fine'
                                  : 'Had issues with mic'}
                              </p>
                            </div>
                          </li>
                          <li className="flex items-center mb-4">
                            <div className="icon rounded-full dark:bg-[#d3d3d3] bg-[#E7EDFC] h-12 w-12 flex items-center justify-center">
                              <img
                                src={iconVideo}
                                alt="device"
                                className="h-auto w-4"
                              />
                            </div>
                            <div class="meta pl-4 ">
                              <p className="text-[#A3AED0] font-normal">
                            Video Quality
                              </p>
                              <p className="dark:text-white  text-[#2B3674] font-semibold">
                                {' '}
                                {deviceDetails.video_quality ? 'Bad' : 'Good'}
                              </p>
                            </div>
                          </li>
                          <li className="flex items-center mb-4">
                            <div className="icon rounded-full dark:bg-[#d3d3d3] bg-[#E7EDFC] h-12 w-12 flex items-center justify-center">
                              <img
                                src={iconInternetSpeed}
                                alt="device"
                                className="h-auto w-4"
                              />
                            </div>
                            <div class="meta pl-4 ">
                              <p className="text-[#A3AED0] font-normal">
                            Internet Speed{' '}
                              </p>
                              <p className="dark:text-white  text-[#2B3674] font-semibold">
                                {deviceDetails.internet_speed_issues
                                  ? 'Bad'
                                  : 'Good'}
                              </p>
                            </div>
                          </li>
                          <li className="flex items-center mb-4">
                            <div className="icon rounded-full dark:bg-[#d3d3d3] bg-[#E7EDFC] h-12 w-12 flex items-center justify-center">
                              <img
                                src={iconBulb}
                                alt="device"
                                className="h-auto w-4"
                              />
                            </div>
                            <div class="meta pl-4 ">
                              <p className="text-[#A3AED0] font-normal">
                            Lighting Condition
                              </p>
                              <p className="dark:text-white  text-[#2B3674] font-semibold">
                                {' '}
                                {deviceDetails.low_light ? 'Bad' : 'Good'}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    )
                  }
                  
                </div>
              </div>
            </div>
          </div>
        ),
      };
    });
  }, [aiAssessmentQuestionAnswers]);

  // console.log([method, location?.state?.selectedData?.interview_method].includes('AI_VIDEO'));
  console.log([method, location?.state?.selectedData?.interview_method]);

  return (
    <div className="annotationsWrapper border rounded-md light:bg-[#F8FAFF] dark:bg-transparent">
      <div className="bg-[#F8FAFF] dark:bg-transparent">
        {reactQueryLoadingHelper(
          <div className="h-96 flex items-center justify-center">
            <Spin />
          </div>,
          <div className="h-96 flex items-center justify-center p-4">
            {DEFAULT_ERROR_MESSAGE}
          </div>,
          <>
            {isEmpty(items) ? (
              <Empty description={<p>No questions found.</p>} />
            ) : (
              <>
                {contextHolder}
                <Tabs
                  tabPosition="left"
                  items={items}
                  className="questionsTab"
                  // style={{ height: '70vh' }}
                />
              </>
            )}
          </>,
          aiAssessmentQuestionAnswersStatus
        )}
      </div>
      {/* -----------------------  Infractions images modal popup starts ----------------------- */}
      <Modal
        destroyOnClose
        open={isInfractionModalOpen}
        onCancel={() => {
          setIsInfractionsModalOpen(false);
          setQuestionDetails(null);
        }}
        footer={null}
        className="infractionsModal"
        width={800}
      >
        <h1 className="mb-4 font-semibold">Infraction Images</h1>
        {reactQueryLoadingHelper(
          <div className="h-96 flex items-center justify-center">
            <Spin />
          </div>,
          <div className="h-96 flex items-center justify-center">
            <Empty isError description={<p>{DEFAULT_ERROR_MESSAGE}</p>} />
          </div>,
          <>
            <Tabs
              defaultActiveKey={'Multiface Infraction'}
              items={[
                {
                  label: 'Multiface Infraction',
                  key: 1,
                  children: (
                    <div>
                      {!isEmpty(infractionImages?.results) ? (
                        <div className="flex flex-wrap -mx-3">
                          {infractionImages.results.map((item) => {
                            return (
                              <div
                                className="w-full sm:w-1/4 px-3"
                                key={item.id}
                              >
                                <img src={item.image_frame} alt="infraction" />
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="flex justify-center items-center">
                          <Empty description={<p>No infraction images</p>} />
                        </div>
                      )}
                    </div>
                  ),
                }, 
                
                // {
                //   label: 'Tab Infraction',
                //   key: 2,
                //   children: (
                //     <div>
                //       {!isEmpty(TabInfractionImgUrl) ? (
                //         <div className="flex flex-wrap -mx-3">
                //           <img src={TabInfractionImgUrl} alt="infraction"></img>
                //         </div>
                //       ) : (
                //         <div className="flex justify-center items-center">
                //           <Empty description={<p>No infraction images</p>} />
                //         </div>
                //       )}
                //     </div>
                //   ),
                // },
              ]}
              onChange={(name) => {
                console.log(name);
              }}
            />
          </>,
          infractionImagesStatus
        )}
      </Modal>
      {/* -----------------------  Infractions images modal popup ends ----------------------- */}

      {/* -----------------------  Interviewer feedback modal popup starts ----------------------- */}
      <Modal
        destroyOnClose
        open={isInterviewFeedbackModalOpen}
        onCancel={() => {
          setIsInterviewFeedbackModalOpen(false);
          setQuestionDetails(null);
        }}
        footer={null}
        className="interviewFeedbackModal"
        width={800}
      >
        <form onSubmit={handleSubmit(interviewerFeedbackSubmit)}>
          <h1 className="text-heading1 font-medium">Interviewer Feedback</h1>
          <div className="content py-spacing2">
            <RadioGroup
              name="is_correct_answer"
              label="Answer"
              control={control}
              rules={{
                required: 'At least 1 option must be chosen.',
                maxLength: 20,
              }}
              options={ANSWER_OPTIONS}
              readOnly={
                location?.state?.selectedData?.has_review_permission ===
                undefined 
                || isModal === true
              }
            />
            <Input
              name="score"
              label="Enter Score (Percentage)"
              placeholder="Enter Score"
              control={control}
              rules={{
                required: 'This field is required.',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Please enter only numbers',
                },
                validate: (value) => {
                  if (value > 100) {
                    return 'Percentage cant be greater than 100';
                  }
                },
              }}
              suffix={<Tooltip title="Score in percentage">%</Tooltip>}
              readOnly={
                location?.state?.selectedData?.has_review_permission ===
                undefined
                || isModal === true
              }
            />
            <Select
              label="Choose Emotion"
              placeholder="Enter Score"
              // style={{ width: '100%' }}
              // fetching={isJobListFetching}
              // onSearch={(value) => {
              //   setSearchText(value);
              // }}
              options={EMOTION_OPTIONS}
              // rhf
              name="emotions"
              control={control}
              allowClear
              onClear={() => {
                setValue('interviewLevel', undefined);
              }}
              rules={{
                required: 'This field is required.',
              }}
              useDefaultTheme
              readOnly={
                location?.state?.selectedData?.has_review_permission ===
                undefined
                || isModal === true
              }
            />
            <TextArea
              name="comments"
              label="Comment"
              placeholder="Comment"
              control={control}
              rules={{
                required: 'This field is required.',
              }}
              readOnly={
                location?.state?.selectedData?.has_review_permission ===
                undefined
                || isModal === true
              }
            />
          </div>
          <div className="flex justify-end">
            <Button
              className="mr-4"
              onClick={() => {
                setIsInterviewFeedbackModalOpen(false);
                setQuestionDetails(null);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                location?.state?.selectedData?.has_review_permission ===
                undefined
                || isModal === true
              }
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal>
      {/* -----------------------  Interviewer feedback modal popup ends ----------------------- */}
    </div>
  );
};

export default memo(Annotations);
