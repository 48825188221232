import React, { useState, useEffect } from 'react';
import {
  useForm,
  Controller,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
// import { Radio, Checkbox, DatePicker, Button, Form, Input, Space } from "antd";
import {
  BarsOutlined,
  AppstoreOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import TextEdit from '../CommonComponents/TextEdit';
import settingService from '../../Services/settingsService';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import { cloneDeep, replace } from 'lodash';
import { isEmpty } from 'lodash';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import Button from 'Components/CommonComponents/Button/Button';

var arrayOption = [];
var arraySelected = [];
var arrayEdit = [];
const AddEvaluationForm = (props) => {
  const navigate = useNavigate();
  const [option, setOption] = useState(false);
  const [multiple, setMultiple] = useState(false);
  const [selectedType, setSelectedType] = useState();
  const [optionValue, setOptionValue] = useState();
  const [isCheck, setIsCheck] = useState(false);
  const [selected, setSelected] = useState(false);
  const [shortAns, setShortAns] = useState(false);
  const [longAns, setLongAns] = useState(false);
  const [isMandatory, setIsMandatory] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [showTextBox, setShowTextBox] = useState(false);
  const [parameterData, setParameterData] = useState();

  const {
    register,
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      optionData: [],
      is_mandatory: false,
      is_vertical: false,
    },
  });
  const { fields, append, prepend, remove, swap, move, insert } =
    useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: 'optionData', // unique name for your Field Array
    });
  console.log('fields', errors);
  const onCancel = () => {
    if (!props.parameterId) {
      arrayEdit = [];
      props.close(false);
    } else {
      arrayEdit = [];
      props.editClose(false);
    }
  };

  const parameterTypes = [
    { value: 'SHORT_ANS', label: 'Short Sentance' },
    { value: 'LONG_ANS', label: 'Long Answers' },
    { value: 'MULTI_CHOICE', label: 'Multiple Choices' },
    { value: 'OPTIONS', label: 'Options' },
    { value: 'BOOL', label: 'Yes/No' },
  ];

  const onChangeQuestionType = (e, onChange) => {
    onChange(e.value);
    setValue('optionData', []);
    console.log('onChangeQuestionType', e.value);

    // if (e.value == "OPTIONS") {
    //   setOption(true);
    // } else {
    //   setOption(false);
    // }
    // if (e.value == "MULTI_CHOICE") {
    //   setMultiple(true);
    // } else {
    //   setMultiple(false);
    // }
    // if (e.value == "SHORT_ANS") {
    //   setShortAns(true);
    // } else {
    //   setShortAns(false);
    // }
    // if (e.value == "LONG_ANS") {
    //   setLongAns(true);
    // } else {
    //   setLongAns(false);
    // }

    // setSelectedType(e.value);
  };
  const choosenType = watch('answer_type');
  console.log('anseer', choosenType);

  const isMultichoice = choosenType === 'MULTI_CHOICE';
  const isOPtions = choosenType === 'OPTIONS';

  const handleMultiChoice = () => {};

  const handleCheck = (e) => {
    if (e.target.checked) {
      setIsMandatory(true);
    } else {
      setIsMandatory(false);
    }
  };
  console.log('isman', isMandatory);
  const handleShow = () => {
    setIsShow(!isShow);
  };
  const onFinish = (values) => {
    console.log('Received values of form:', values);
  };
  const handelTextBox = (e) => {
    setShowTextBox(!showTextBox);
  };
  const uncheckCheckbox = (checkbox_id) => {
    if (
      option ||
      (parameterData && parameterData?.answer_type == 'OPTIONS')
    ) {
      var check_boxes = document.getElementsByClassName(
        'option_or_multi_checkbox',
      );
      for (let i = 0; i < check_boxes.length; i++) {
        check_boxes[i].checked = false;
      }
      document.getElementById(checkbox_id).checked = true;
    }
  };
  const editOPtion = (id, e) => {
    console.log('optionid', id);
    console.log('optionEvent', e.target.value);
    if (arrayEdit.length > 0) {
      for (let i = 0; i < arrayEdit.length; i++) {
        if (arrayEdit[i].id == id) {
          arrayEdit[i].option = e.target.value;
        }
      }
      console.log('testarray', arrayEdit);
    }
  };
  const multiArrayCheckbox = (id, e) => {
    if (arrayEdit.length > 0) {
      for (let i = 0; i < arrayEdit.length; i++) {
        if (arrayEdit[i].id == id) {
          if (e.target.checked) {
            arrayEdit[i].is_text_required = true;
          } else {
            arrayEdit[i].is_text_required = false;
          }
        }
      }
      console.log('cgecj', arrayEdit);
    }
  };
  const handleOption = (e) => {
    if (multiple && optionValue) {
      arrayOption.push({
        option: optionValue,
        is_text_required: isCheck,
      });
      setIsCheck(false);
      console.log('options', arrayOption);
    } else if (option && optionValue) {
      arraySelected.push({
        option: optionValue,
        is_text_required: showTextBox,
      });
      setShowTextBox(false);
      console.log('optiosnsss', arraySelected);
    }
  };
  const getParameterData = async () => {
    await settingService
      .getParameteById(props.parameterId)
      .then((response) => {
        setParameterData(response.data.data);
        prescreenInfo(response?.data?.data);
        //setIsShow(response?.data?.data?.is_vertical)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log('parameterData', parameterData?.multi_options_answers);
  const prescreenInfo = (data) => {
    setValue('question', data?.question);
    setValue('is_mandatory', data?.is_mandatory);
    setValue('answer_type', data?.answer_type);
    setValue('is_vertical', data?.is_vertical);
  };
  useEffect(() => {
    getParameterData();
  }, [props.isEdit]);

  console.log('qtype', choosenType?.value);
  useEffect(() => {
    if (isEmpty(parameterData?.multi_options_answers)) return;
    var OPTION_DATA = { optionData: parameterData?.multi_options_answers };
    console.log('OPTION_DATA', OPTION_DATA);
    setValue('optionData', OPTION_DATA.optionData);
    replace(parameterData?.multi_options_answers);
  }, [parameterData]);

  const sendData = async (data) => {
    if ((isMultichoice || isOPtions) && data.optionData.length == 0) {
      toast.error('Please Add Options ');
    } else if (
      isMultichoice &&
      data.optionData.length > 0 &&
      data.optionData.length < 3
    ) {
      toast.error('minimum three options required');
    } else if (
      isOPtions &&
      data.optionData.length > 0 &&
      data.optionData.length < 2
    ) {
      toast.error('minimum Two options required');
    } else {
      await settingService
        .addEvaluationParameter(data)
        .then(
          (response) => {
            console.log(response.data.data);
            toast.success('Successfully added new item..!!');
            props.close(false);
          },
          (error) => {
            console.log('erroe', error?.response?.data?.error_info);
            const errorsObj = error?.response?.data?.error_info;
            console.log('objerror', Object.keys(errorsObj));
            Object.keys(errorsObj).forEach((field) => {
              const messages = errorsObj[field];
              setError(field, {
                type: 'server',
                message: messages.join('. '),
              });
            });
          },
        )
        .catch((error) => {
          console.log('errr');
          toast.error('Something went wrong..!!');
        });
    }
  };
  const handelBack = () => {
    navigate(props.close(false));
  };
  const updateData = async (data) => {
    // if (parameterData?.answer_type == "OPTIONS" || parameterData?.answer_type == "MULTI_CHOICE") {
    //   var arrays = document.getElementsByClassName("optiontextbox")
    //   var arrayEdit = [];
    //   for (let i = 0; i < arrays.length; i++) {
    //     if (arrays[i].getAttribute('data-optionKey') != null) {
    //       var checkbox = document.getElementById('option_check_' + arrays[i].getAttribute('data-optionKey'))
    //     } else {
    //       var checkbox = document.getElementById(arrays[i].getAttribute('data-optionKeyExisted'))
    //     }
    //     arrayEdit.push({
    //       option: arrays[i].value,
    //       is_text_required: checkbox.checked,
    //     });
    //   }
    // }
    // if (parameterData && parameterData.answer_type == "SHORT_ANS") {
    //   data['question'] = data.question
    //   data['answer_type'] = "SHORT_ANS"
    //   data['is_mandatory'] = isMandatory
    // }
    // if (parameterData && parameterData.answer_type == "LONG_ANS") {
    //   data['question'] = data.question
    //   data['answer_type'] = "LONG_ANS"
    //   data['is_mandatory'] = isMandatory
    // }
    // if (parameterData && parameterData.answer_type == "OPTIONS") {
    //   data['question'] = data.question
    //   data['answer_type'] = "OPTIONS"
    //   data['is_vertical'] = data.is_vertical
    //   data['is_mandatory'] = data.is_mandatory
    //   data['multi_options_answers'] = fields
    // }
    // if (parameterData && parameterData.answer_type == "MULTI_CHOICE") {
    //   data['question'] = data.question
    //   data['is_mandatory'] = data.is_mandatory
    //   data['answer_type'] = "MULTI_CHOICE"
    //   data['is_vertical'] = data.is_vertical
    //   data['multi_options_answers'] = fields
    // }
    console.log('testdata', data);
    if ((isMultichoice || isOPtions) && data.optionData.length == 0) {
      toast.error('Please Add Options ');
    } else if (
      isMultichoice &&
      data.optionData.length > 0 &&
      data.optionData.length < 3
    ) {
      toast.error('minimum three options required');
    } else if (
      isOPtions &&
      data.optionData.length > 0 &&
      data.optionData.length < 2
    ) {
      toast.error('minimum Two options required');
    } else {
      await settingService
        .editParameter(props.parameterId, data)
        .then((response) => {
          console.log(response.data.data);
          toast.success('Successfully Edited!!');
          arrayEdit = [];
          props.close(false);
        })
        .catch((error) => {
          console.log('errr');
          toast.error('Something went wrong..!!');
          arrayEdit = [];
        });
    }
  };
  const onSubmit = async (data) => {
    if (props.parameterId) {
      updateData(data);
    } else {
      sendData(data);
    }
  };

  const OptionListView = (data) => {
    return (
      <div className="pl-5">
        <Form
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          style={{
            maxWidth: '75%',
          }}
          autoComplete="off"
        >
          {parameterData &&
            parameterData?.multi_options_answers?.map((item, index) => (
              <div className="flex flex-wrap">
                <Form.Item
                  key={index}
                  rules={[{ required: true, message: 'Name is required' }]}
                  className="w-1/2"
                >
                  <Input
                    type="text"
                    data-optionKeyExisted={
                      'option_check_already_existed_field_' + index
                    }
                    defaultValue={item.option ? item.option : ''}
                    key={index}
                    placeholder="Option Name"
                    className="rounded-md h-[36px] flex-shrink-0 p-4 optiontextbox"
                    onChange={(e) => editOPtion(item.id, e)}
                  />
                </Form.Item>
                <Form.Item
                  key={index}
                  rules={[{ required: true, message: 'Name is required' }]}
                  className="mr-[15px] ml-28"
                >
                  {parameterData &&
                    parameterData.answer_type == 'MULTI_CHOICE' && (
                      <Checkbox.Group
                        defaultValue={
                          item.is_text_required == true
                            ? 'is_text_required'
                            : ''
                        }
                      >
                        <Checkbox
                          id={
                            'option_check_already_existed_field_' + index
                          }
                          value={'is_text_required'}
                          onChange={(e) => multiArrayCheckbox(item.id, e)}
                        >
                          Show TextBox
                        </Checkbox>
                      </Checkbox.Group>
                    )}
                </Form.Item>
                <Form.Item
                  key={index}
                  rules={[{ required: true, message: 'Name is required' }]}
                  className="flex"
                >
                  {parameterData &&
                    parameterData.answer_type == 'OPTIONS' && (
                      <div className="flex relative">
                        <input
                          defaultChecked={item?.is_text_required}
                          className={
                            'border-4 w-5 h-4 border-black mt-[6px] option_or_multi_checkbox ' +
                            'option_element_' +
                            index
                          }
                          id={
                            'option_check_already_existed_field_' + index
                          }
                          type="checkbox"
                          onChange={() =>
                            uncheckCheckbox(
                              'option_check_already_existed_field_' +
                                index,
                            )
                          }
                        />

                        <label className="pb-6 pl-4 mt-[2px]">
                          Show TextBox
                        </label>
                      </div>
                    )}
                </Form.Item>
              </div>
            ))}

          <Form.List name="users" className="w-full">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'first']}
                      rules={[
                        {
                          required: true,
                          message: 'Missing options',
                        },
                      ]}
                      className="w-full mt-4"
                    >
                      <Input
                        control={control}
                        data-optionKey={name}
                        placeholder="Option Name"
                        className="rounded-md h-[36px] flex-shrink-0 p-4 optiontextbox"
                        onChange={(e) => setOptionValue(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'first']}
                      rules={[
                        {
                          required: true,
                          message: 'Missing options',
                        },
                      ]}
                      className="w-1/2 pt-4"
                    >
                      {(multiple ||
                        (parameterData &&
                          parameterData.answer_type ==
                            'MULTI_CHOICE')) && (
                        <Checkbox.Group
                          // onChange={onCommunicationChange}
                          //   value={communicationValue}
                          className="multiple-choice ml-5"
                        >
                          <Checkbox
                            id={'option_check_' + name}
                            className={
                              'option_or_multi_checkbox ' +
                              'option_element_' +
                              name
                            }
                            value={'is_text_required'}
                            //onChange={(e) => handleCheckBox(e)}
                            //onChange={() => uncheckCheckbox('option_element_'+name)}
                          >
                            Show TextBox
                          </Checkbox>
                        </Checkbox.Group>
                      )}
                      {(option ||
                        (parameterData &&
                          parameterData.answer_type == 'OPTIONS')) && (
                        <div className="flex absolute ml-2">
                          <input
                            className={
                              'border-4 w-5 h-4 border-black mt-[6px] option_or_multi_checkbox ' +
                              'option_element_' +
                              name
                            }
                            id={'option_check_' + name}
                            type="checkbox"
                            onChange={() =>
                              uncheckCheckbox('option_check_' + name)
                            }
                          />
                          <label className="pl-2 pb-6 mt-[2px]">
                            Show TextBox
                          </label>
                        </div>
                      )}
                      {(multiple ||
                        (parameterData &&
                          parameterData.answer_type ==
                            'MULTI_CHOICE')) && (
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          className="list_icon pr-20 w-1/3"
                        />
                      )}
                      {(option ||
                        (parameterData &&
                          parameterData.answer_type == 'OPTIONS')) && (
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          className="option-minus-circle w-1/4"
                          id="multiple-minus-circle"
                        />
                      )}
                    </Form.Item>
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="border"
                    className="w-[107px] h-[30px] flex-shrink-0 rounded-4 bg-teal-600 text-white ml-[580px] mt-[23px]"
                    onClick={() => {
                      add();
                      // handleOption();
                    }}
                    icon={
                      <PlusOutlined className="w-[10px] h-[9.677px] flex-shrink-0 text-white" />
                    }
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          {/* <Form.List name="users" className="w-full flex flex-wrap">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      // marginTop:10,
                      // marginLeft:26,
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "first"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing options",
                        },
                      ]}
                      className="w-full"
                    >
                      <Input
                        control={control}
                        data-optionKey={name}
                        placeholder="Option Name"
                        className="rounded-md h-[36px] flex-shrink-0 p-4 optiontextbox"
                        onChange={(e) => setOptionValue(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "first"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing options",
                        },
                      ]}
                      className="pt-4"
                    >
                      {(multiple || parameterData && parameterData.answer_type == "MULTI_CHOICE") && (
                        <Checkbox.Group
                          // onChange={onCommunicationChange}
                          //   value={communicationValue}
                          className="mr-[15px] ml-20"
                        >
                          <Checkbox
                            id={'option_check_' + name}
                            className={"option_or_multi_checkbox " + "option_element_" + name}
                            value={"is_text_required"}
                          //onChange={(e) => handleCheckBox(e)}
                          //onChange={() => uncheckCheckbox('option_element_'+name)}

                          >
                            Show TextBox
                          </Checkbox>
                        </Checkbox.Group>
                      )
                      }
                      {(option || parameterData && parameterData.answer_type == "OPTIONS") && (
                        <div className="flex mr-[25px] ml-20 w-1/3">
                          <input
                            className={"border-4 w-5 h-4 border-black mt-[6px] option_or_multi_checkbox " + "option_element_" + name}
                            id={'option_check_' + name}
                            type="checkbox"
                            onChange={() => uncheckCheckbox('option_check_' + name)}
                          />
                          <label className="pb-6 pl-2 mt-[2px]">
                            Show TextBox
                          </label>
                        </div>
                      )}
                      {(multiple || parameterData && parameterData.answer_type == "MULTI_CHOICE") && (
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          className="w-1/3 list_icon "
                        />
                      )
                      }
                      {(option || parameterData && parameterData.answer_type == "OPTIONS") && (
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          className="option-minus-circle mt-2 "
                          id="multiple-minus-circle"
                        />
                      )
                      }
                    </Form.Item>
                  </Space>
                ))}
                <Form.Item>

                  <Button
                    type="border"
                    className="w-[107px] h-[30px] flex-shrink-0 rounded-4 bg-teal-600 text-white ml-[580px] mt-[23px]"
                    onClick={() => {
                      add();
                      // handleOption();
                    }}
                    icon={<PlusOutlined className="w-[10px] h-[9.677px] flex-shrink-0 text-white" />}
                  >
                    Add field
                  </Button>

                </Form.Item>
              </>
            )}
          </Form.List> */}
        </Form>
      </div>
    );
  };
  const handleCheckBox = (e, index, onChange, name) => {
    onChange(e);
    if (isOPtions || parameterData?.answer_type == 'OPTIONS') {
      const newArray = cloneDeep(fields);
      newArray.forEach((item, itemIndex) => {
        if (itemIndex === index) {
          setValue(name, e.target.checked);
        } else {
          setValue(`optionData.${itemIndex}.is_text_required`, false);
        }
      });
    }
  };
  console.log('feilds', fields);
  const OptionListViews = () => {
    return fields.map((field, index) => (
      <div className="flex pt-10 ">
        <div class="w-3/4 px-8">
          <Input
            key={field.id}
            name={`optionData.${index}.option`}
            label={false}
            control={control}
            rules={{
              required: 'This field is required',
            }}
            //className="rounded-md border border-gray-400 shadow-md h-12 w-full flex-shrink-0 pl-4"
          />
        </div>
        <div className="px-8 w-1/3">
          <Checkbox
            key={field.id}
            name={`optionData.${index}.is_text_required`}
            showUpperLabel={false}
            label="Show text box"
            control={control}
            onChange={(e, onChange) =>
              handleCheckBox(
                e,
                index,
                onChange,
                `optionData.${index}.is_text_required`,
              )
            }
            // disabled
            // rules={{
            //   required: 'Please check.',
            //   maxLength: 20
            // }}
          />
        </div>
        <div className="px-8 mt-1 ">
          <MinusCircleOutlined
            onClick={() => remove(index)}
            className="list_icon"
          />
        </div>

        {/* <input
          key={field.id} // important to include key with field's id
          {...register(`test.${index}.value`)}
          className="w-f h-10  rounded-md border pl-4"
        /> */}
      </div>
    ));
  };
  return (
    <div className="w-full">
      <div
        className="flex items-center cursor-pointer"
        type="text"
        onClick={handelBack}
      >
        <ArrowBackIcon />{' '}
        <span className="ml-4 text-base font-medium">Settings</span>
      </div>
      <div className="mx-4">
        <h3 className="text-black text-base font-medium mb-4 mt-4">
          {props.parameterId
            ? 'Edit Evaluation Parameter'
            : 'Add Evaluation Parameter'}
        </h3>
        <form
          className="pt-5 w-full h-auto"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="bg-secondaryBgMain w-full rounded-md h-fit pb-10 ">
            <div className="flex md:flex-row flex-col">
              <div className="md:w-2/3 mt-4 w-full px-6">
                <Input
                  name="question"
                  label="Question"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                  //className="rounded-md border border-gray-400 shadow-md h-12 w-full flex-shrink-0 pl-4"
                />
              </div>
              <div className="w-1/3 mt-16 px-10">
                <Checkbox
                  name="is_mandatory"
                  showUpperLabel={false}
                  label="Mandatory"
                  control={control}
                  // disabled
                  // rules={{
                  //   required: 'Please check.',
                  //   maxLength: 20
                  // }}
                />
              </div>
            </div>
            <div className="flex md:flex-row flex-col">
              <div className="w-1/2 px-6">
                <Select
                  name="answer_type"
                  control={control}
                  label="Parameter Type"
                  placeholder="Select Parameter Type"
                  onChange={onChangeQuestionType}
                  options={parameterTypes}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
              {(isMultichoice ||
                parameterData?.answer_type == 'MULTI_CHOICE') && (
                <div className="w-1/3 mt-12 px-10">
                  <Checkbox
                    name="is_vertical"
                    showUpperLabel={false}
                    label="List Vertically"
                    control={control}
                  />
                </div>
              )}
              {(isOPtions || parameterData?.answer_type == 'OPTIONS') && (
                <div className="w-1/3 mt-12 px-10">
                  <Checkbox
                    name="is_vertical"
                    showUpperLabel={false}
                    label="List Vertically"
                    control={control}
                  />
                </div>
              )}
            </div>
            {(isMultichoice || isOPtions) && (
              <div className="w-1/2 flex pb-8">
                <div>
                  {OptionListViews()}
                  <Button
                    type="border"
                    className="w-[107px] h-[30px] flex-shrink-0 rounded-4 bg-teal-600 text-white ml-[580px] mt-[23px]"
                    onClick={() => {
                      append({
                        option: '',
                        is_text_required: false,
                      });
                    }}
                    icon={
                      <PlusOutlined className="w-[10px] h-[9.677px] flex-shrink-0 text-white" />
                    }
                  >
                    Add field
                  </Button>
                </div>
              </div>
            )}
            {/* {(isOPtions || parameterData?.answer_type == "OPTIONS") && (
              <div className="w-1/2 flex pb-8">
                <div>
                  <Button
                    type="border"
                    className="w-[107px] h-[30px] flex-shrink-0 rounded-4 bg-teal-600 text-white  ml-[580px] mt-[23px] mt-[23px]"
                    onClick={() => {
                      append({
                        option: "",
                        is_text_required: false
                      });
                    }}
                    icon={<PlusOutlined className="w-[10px] h-[9.677px] flex-shrink-0 text-white" />}
                  >
                    Add field
                  </Button>
                  {OptionListViews()}
                </div>
              </div>
            )
            } */}
          </div>
          <div className="flex flex-wrap justify-end mt-2">
            <button className="popup_skills primary-btn px-1 ">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
  // return (
  //   <div className="w-full">
  //     <div className="flex items-center cursor-pointer" type="text" onClick={handelBack}>
  //       <ArrowBackIcon /> <span className="ml-4 text-base font-medium">Settings</span>
  //     </div>
  //     <h3 className="text-black text-base font-medium mb-4 mt-4">{props.parameterId ? "Edit Evaluation Parameter" : "Add Evaluation Parameter"}</h3>
  //     <form className="pt-5 w-full h-auto" onSubmit={handleSubmit(onSubmit)}>
  //       <div className="w-full rounded-md bg-white  h-fit pb-10 p-4 mb-4 ">
  //         <div className="w-full px-3 mb-2">

  //           <div className="flex md:flex-row flex-col">
  //             <div className="md:w-2/3 mt-4 w-full">
  //               <Input
  //                 name="question"
  //                 label="Question"
  //                 control={control}
  //                 rules={{
  //                   required: 'This field is required'
  //                 }}
  //               //className="rounded-md border border-gray-400 shadow-md h-12 w-full flex-shrink-0 pl-4"
  //               />
  //             </div>

  //             <div className="w-1/3 mt-16 px-8">

  //               <Checkbox
  //                 name="is_mandatory"
  //                 showUpperLabel={false}
  //                 label="Mandatoey"
  //                 control={control}
  //               // disabled
  //               // rules={{
  //               //   required: 'Please check.',
  //               //   maxLength: 20
  //               // }}
  //               />
  //             </div>
  //           </div>
  //           <div className="w-full mt-[24px]">
  //             <div className="flex flex-wrap">
  //               <div className="w-1/2 mt-[9px]">
  //                 <Select
  //                   name="answer_type"
  //                   control={control}
  //                   label="Parameter Type"
  //                   placeholder="Select Parameter Type"
  //                   onChange={onChangeQuestionType}
  //                   options={parameterTypes}
  //                   rules={{
  //                     required: 'This field is required'
  //                   }}

  //                 />
  //               </div>
  //               {(multiple || parameterData?.answer_type == "MULTI_CHOICE") &&
  //                 <div className="ml-20 " >
  //                   <div className="mt-16">
  //                     <Checkbox
  //                       name="is_vertical"
  //                       showUpperLabel={false}
  //                       label="List Vertically"
  //                       control={control}
  //                     // disabled
  //                     // rules={{
  //                     //   required: 'Please check.',
  //                     //   maxLength: 20
  //                     // }}
  //                     />
  //                   </div>
  //                 </div>
  //               }
  //               {(option || parameterData?.answer_type == "OPTIONS") &&
  //                 <div className="">
  //                   <Checkbox
  //                     name="is_vertical"
  //                     showUpperLabel={false}
  //                     label="List Vertically"
  //                     control={control}
  //                   // disabled
  //                   // rules={{
  //                   //   required: 'Please check.',
  //                   //   maxLength: 20
  //                   // }}
  //                   />
  //                 </div>
  //               }
  //             </div>
  //             {(isMultichoice || parameterData?.answer_type == "MULTI_CHOICE") &&
  //               (
  //                 <div className="w-1/2 flex justify-center items-center">
  //                   <div>
  //                     <Button
  //                       type="border"
  //                       className="w-[107px] h-[30px] flex-shrink-0 rounded-4 bg-teal-600 text-white ml-[580px] mt-[23px]"
  //                       onClick={() => {
  //                         append({
  //                           option: "",
  //                           is_text_required: false
  //                         })
  //                       }}
  //                       icon={<PlusOutlined className="w-[10px] h-[9.677px] flex-shrink-0 text-white" />}
  //                     >
  //                       Add field
  //                     </Button>
  //                     {OptionListViews()}
  //                   </div>

  //                 </div>
  //               )

  //             }
  //             {(isOPtions || parameterData?.answer_type == "OPTIONS") && (
  //               <div className="pt-4">
  //                 <Button
  //                   type="border"
  //                   className="w-[107px] h-[30px] flex-shrink-0 rounded-4 bg-teal-600 text-white  mt-[23px]"
  //                   onClick={() => {
  //                     append({
  //                       option: "",
  //                       is_text_required: false
  //                     });
  //                   }}
  //                   icon={<PlusOutlined className="w-[10px] h-[9.677px] flex-shrink-0 text-white" />}
  //                 >
  //                   Add field
  //                 </Button>
  //                 {OptionListViews()}
  //               </div>
  //             )
  //             }

  //             {/*
  //             {(multiple || parameterData?.answer_type == "MULTI_CHOICE") && (
  //               <div className="pt-4">
  //                 {OptionListView()}
  //               </div>
  //             )} */}
  //           </div>
  //         </div>
  //         {/* <div className="w-full px-3 mb-2">
  //           <div className="w-full mt-[20px]">
  //             <label className="text-black font-poppins text-xs font-normal leading-normal pb-[9px]">
  //               Question
  //             </label>
  //           </div>
  //           <div className="flex">
  //             <div className="w-2/3 mt-2">
  //               <input
  //                 type="text"
  //                 {...register("question", {
  //                   required: "This field is required",
  //                 })}
  //                 className="w-full h-12 flex-shrink-0 rounded-md border border-gray-400 border-opacity-75 shadow-md pl-4"
  //               />
  //               {errors && errors.question && (
  //                 <p className="text-red-600 text-xs font-semibold text-start ml-[50px]">
  //                   *{errors.question.message}
  //                 </p>
  //               )}
  //             </div>
  //             <div className="w-1/3 flex relative ml-20">
  //               <input
  //                 // id={data.id}
  //                 type="checkbox"
  //                 //defaultChecked={parameterData?.is_mandatory_field}
  //                 onChange={(e) => handleCheck(e)}
  //                 className="border-4 w-[13px] h-[13px] mt-[20px] border-black "
  //               />
  //               <label className="text-black font-normal leading-normal mb-2 mt-5 text-x pl-[10px]">
  //                 Mandatory
  //               </label>
  //             </div>
  //           </div>
  //           <div className="w-full mt-[24px]">
  //             <label className="text-black text-xs font-normal leading-normal">
  //               Parameter Type
  //             </label>
  //             <div className="flex flex-wrap">
  //               <div className="w-2/3 mt-[9px]">
  //                 <select
  //                   id="countries"
  //                   className="w-full h-12 flex-shrink-0 rounded-md border border-gray-400 border-opacity-75 shadow-md pl-4"
  //                   {...register("answer_type", {
  //                     required: "This field is required",
  //                   })}
  //                   onChange={onChangeQuestionType}
  //                 >
  //                   <option selected value="">Choose Parameter Type</option>
  //                   <option value="SHORT_ANS">Short Sentance</option>
  //                   <option value="LONG_ANS">Long Answer</option>
  //                   <option value="OPTIONS">Options</option>
  //                   <option value="MULTI_CHOICE">Multiple Choices</option>
  //                 </select>
  //                 {errors && errors.answer_type && (
  //                   <p className="text-red-600 text-xs font-semibold text-start ml-12">
  //                     *{errors.answer_type.message}
  //                   </p>
  //                 )}
  //               </div>
  //               {(multiple || parameterData?.answer_type == "MULTI_CHOICE") &&
  //                 <div className="ml-20 mt-[20px] flex relative" >
  //                   <input
  //                     // id={data.id}
  //                     type="checkbox"
  //                     defaultChecked={parameterData && parameterData?.is_vertical}
  //                     onChange={(e) => handleShow(e)}
  //                     className="border-4 w-5 h-4 border-black mt-1"
  //                   />
  //                   <label className="text-black font-normal leading-normal  text-x ">
  //                     List vertically
  //                   </label>
  //                 </div>
  //               }
  //               {(option || parameterData?.answer_type == "OPTIONS") &&
  //                 <div className="ml-20 flex relative mt-[14px] ">
  //                   <input
  //                     // id={data.id}
  //                     type="checkbox"
  //                     defaultChecked={parameterData && parameterData?.is_vertical}
  //                     onChange={(e) => handleShow(e)}
  //                     className="border-4 w-5 h-4 border-black  mt-1"
  //                   />
  //                   <label className="text-black font-normal leading-normal  text-x pl-[10px]">
  //                     List vertically
  //                   </label>
  //                 </div>
  //               }
  //             </div>
  //             {(option || parameterData?.answer_type == "OPTIONS") && (
  //               <div className="pt-4">
  //                 {OptionListView()}
  //               </div>
  //             )}
  //             {(multiple || parameterData?.answer_type == "MULTI_CHOICE") && (
  //               <div>
  //                 {OptionListView()}
  //               </div>
  //             )}
  //           </div>
  //         </div> */}

  //       </div>
  //       {/* <div className="pt-4">
  //         {
  //           OptionListViews()
  //         }
  //       </div> */}
  //       <div className="flex flex-wrap justify-end mt-2">
  //         <button className="popup_skills primary-btn px-1 ">Save</button>
  //       </div>
  //     </form>

  //   </div>
  // );
};

export default AddEvaluationForm;
