import axios from 'axios';
import authHeader from './authHeader';
import Config from './Config';

const getTranscationData = (params = {}) => {
  params['limit'] = 100;
  params['offset'] = 0;
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/communications/list-transactions/`,
    config,
  );
};
const getTranscationTemplateData = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/communications/list-whatsapp-templates/`,
    config,
  );
};

const getTranscationDataById = (selectedTransaction) => {
  return axios.get(
    Config.API_URL +
      `api/v1/communications/list-transactions/${selectedTransaction}/?reset=false`,
    {
      headers: authHeader(),
    },
  );
};
const getRestTransactionById = (selectedTransaction) => {
  return axios.get(
    Config.API_URL +
      `api/v1/communications/list-transactions/${selectedTransaction}/?reset=true`,
    {
      headers: authHeader(),
    },
  );
};
const addEmailContent = (
  data,
  getTransactionId,
  subjectName,
  description,
) => {
  return axios.post(
    Config.API_URL +
      `api/v1/communications/email-template-map/?company_domain=https://ndz.yvi.ai/`,
    {
      transaction: getTransactionId,
      email_subject: subjectName,
      content_html: description,
    },
    {
      headers: authHeader(),
    },
  );
};
const activeEmailTransaction = (getTransactionId, activeStatus) => {
  return axios.put(
    Config.API_URL +
      `api/v1/communications/enable-or-disable-transaction/`,
    {
      transaction: getTransactionId,
      transaction_type: 'email',
      is_active: activeStatus,
    },
    {
      headers: authHeader(),
    },
  );
};
const editEmailContent = (
  data,
  getTransactionId,
  id,
  subjectName,
  description,
) => {
  return axios.put(
    Config.API_URL +
      `api/v1/communications/email-template-map/${id}/?company_domain=https://ndz.yvi.ai/`,
    {
      transaction: getTransactionId,
      email_subject: subjectName,
      content_html: description,
    },
    {
      headers: authHeader(),
    },
  );
};
const getTemplateDetails = () => {
  return axios.get(
    Config.API_URL +
      `api/v1/communications/whatsapp-template-company-admin/?company_domain=https://ndz.yvi.ai/`,
    {
      headers: authHeader(),
    },
  );
};
const addWhatsAppTemplate = (body, params, id) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.put(
    `${Config.API_URL}api/v1/communications/whatsapp-template-map/${id}/?company_domain=https://ndz.yvi.ai/`,
    body,
    config,
  );
};

const createWhatsAppTemplate = (body, params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.post(
    `${Config.API_URL}api/v1/communications/whatsapp-template-map/?company_domain=https://ndz.yvi.ai/`,
    body,
    config,
  );
};
const getTranscationVoiceById = (selectedTransaction) => {
  return axios.get(
    Config.API_URL +
      `api/v1/communications/list-voice-transactions/${selectedTransaction}/?reset=false`,
    {
      headers: authHeader(),
    },
  );
};
const activeVoiceTranscation = (getTransactionId, activeStatus) => {
  return axios.put(
    Config.API_URL +
      `api/v1/communications/enable-or-disable-transaction/`,
    {
      transaction: getTransactionId,
      transaction_type: 'voicebot',
      is_active: activeStatus,
    },
    {
      headers: authHeader(),
    },
  );
};
const getResetVoiceById = (selectedTransaction) => {
  return axios.get(
    Config.API_URL +
      `api/v1/communications/list-voice-transactions/${selectedTransaction}/?reset=true`,
    {
      headers: authHeader(),
    },
  );
};
const addVoiceContent = (data, getTransactionId, content) => {
  return axios.post(
    Config.API_URL +
      `api/v1/communications/voice-template-map/?company_domain=https://ndz.yvi.ai/`,
    {
      transaction: getTransactionId,
      audio_content: content,
    },
    {
      headers: authHeader(),
    },
  );
};
const editVoiceContent = (data, getTransactionId, id, content) => {
  return axios.put(
    Config.API_URL +
      `api/v1/communications/voice-template-map/${id}/?company_domain=https://ndz.yvi.ai/`,
    {
      transaction: getTransactionId,
      audio_content: content,
    },
    {
      headers: authHeader(),
    },
  );
};
const getTranscationNotificationById = (selectedTransaction) => {
  return axios.get(
    Config.API_URL +
      `api/v1/communications/list-push-transactions/${selectedTransaction}/?reset=false`,
    {
      headers: authHeader(),
    },
  );
};
const getResetNotificationById = (selectedTransaction) => {
  return axios.get(
    Config.API_URL +
      `api/v1/communications/list-push-transactions/${selectedTransaction}/?reset=true`,
    {
      headers: authHeader(),
    },
  );
};
const addPushNotification = (data, getTransactionId, content) => {
  return axios.post(
    Config.API_URL +
      `api/v1/communications/push-template-map/?company_domain=https://ndz.yvi.ai/`,
    {
      transaction: getTransactionId,
      title: data.title,
      message: content,
    },
    {
      headers: authHeader(),
    },
  );
};
const editPushNotification = (data, getTransactionId, id, content) => {
  return axios.put(
    Config.API_URL +
      `api/v1/communications/push-template-map/${id}/?company_domain=https://ndz.yvi.ai/`,
    {
      transaction: getTransactionId,
      title: data.title,
      message: content,
    },
    {
      headers: authHeader(),
    },
  );
};
const activeNotificationTranscation = (getTransactionId, activeStatus) => {
  return axios.put(
    Config.API_URL +
      `api/v1/communications/enable-or-disable-transaction/`,
    {
      transaction: getTransactionId,
      transaction_type: 'pushnotification',
      is_active: activeStatus,
    },
    {
      headers: authHeader(),
    },
  );
};
const addSmsContent = (data, getTransactionId, content) => {
  return axios.post(
    Config.API_URL +
      `api/v1/communications/sms-template-map/?company_domain=https://ndz.yvi.ai/`,
    {
      transaction: getTransactionId,
      sms_contents: content,
    },
    {
      headers: authHeader(),
    },
  );
};
const getTranscationSmsById = (selectedTransaction) => {
  return axios.get(
    Config.API_URL +
      `api/v1/communications/list-sms-transactions/${selectedTransaction}/?company_domain=https://ndz.yvi.ai/`,
    {
      headers: authHeader(),
    },
  );
};
const activeSmsTranscation = (getTransactionId, activeStatus) => {
  return axios.put(
    Config.API_URL +
      `api/v1/communications/enable-or-disable-transaction/`,
    {
      transaction: getTransactionId,
      transaction_type: 'sms',
      is_active: activeStatus,
    },
    {
      headers: authHeader(),
    },
  );
};
const editSmsContent = (
  data,
  getTransactionId,
  id,
  subjectName,
  description,
) => {
  return axios.put(
    Config.API_URL +
      `api/v1/communications/sms-template-map/${id}/?company_domain=https://ndz.yvi.ai/`,
    {
      transaction: getTransactionId,
      kaleyra_template_id: subjectName,
      sms_contents: description,
    },
    {
      headers: authHeader(),
    },
  );
};
const addOfferLetterTransaction = (data) => {
  return axios.post(
    Config.API_URL + `api/v1/communications/company-offer-letter/`,
    {
      transaction: data.transaction,
    },
    {
      headers: authHeader(),
    },
  );
};
const getOfferLetterTransaction = () => {
  return axios.get(
    Config.API_URL + `api/v1/communications/company-offer-letter/`,
    {
      headers: authHeader(),
    },
  );
};
const getOfferLetterTransactionById = (selectedTransaction) => {
  return axios.get(
    Config.API_URL +
      `api/v1/communications/company-offer-letter/${selectedTransaction}/?reset=false`,
    {
      headers: authHeader(),
    },
  );
};
const updateOfferTransaction = (
  data,
  getTransactionId,
  id,
  subjectName,
  description,
  signatureDetails,
) => {
  return axios.put(
    Config.API_URL + `api/v1/communications/company-offer-letter/${id}/`,
    {
      transaction: getTransactionId,
      email_subject: subjectName,
      content_html: description,
      email_signature: signatureDetails,
    },
    {
      headers: authHeader(),
    },
  );
};

const getOfferTransactionTypes = () => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    Config.API_URL + 'api/v1/communications/list-offer-parameters/',
    config,
  );
};

export const getWhatsAppTemplatePreview = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };

  return axios.get(
    `${Config.API_URL}api/v1/communications/wati-template-preview/`,
    config,
  );
};

const getRecruiters = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };

  return axios.get(
    Config.API_URL + 'api/v1/accounts/list-recruiters/',
    config,
  );
};

const getInterviewers = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };

  return axios.get(
    Config.API_URL + 'api/v1/accounts/list-subusers/',
    config,
  );
};

const getCandidatesByJobId = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };

  return axios.get(
    Config.API_URL +
      'api/v1/candidates/job-candidates-list/?status=FINALIZED',
    config,
  );
};

const getCommunicationLogsWithListView = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };

  return axios.get(
    Config.API_URL + 'api/v1/communications/communication-logs-list/',
    config,
  );
};

const getCommunicationLogsWithCardView = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };

  return axios.get(
    Config.API_URL + 'api/v1/communications/communication-logs-diagrams/',
    config,
  );
};
const activeOfferLetterTranscation = (getTransactionId, activeStatus) => {
  return axios.put(
    Config.API_URL +
      `api/v1/communications/enable-or-disable-transaction/`,
    {
      transaction: getTransactionId,
      transaction_type: 'OFFER_LETTER',
      is_active: activeStatus,
    },
    {
      headers: authHeader(),
    },
  );
};
const CommunicationService = {
  getTranscationData,
  getTranscationDataById,
  addEmailContent,
  getTemplateDetails,
  addWhatsAppTemplate,
  createWhatsAppTemplate,
  editEmailContent,
  getTranscationVoiceById,
  addVoiceContent,
  editVoiceContent,
  getTranscationNotificationById,
  addPushNotification,
  editPushNotification,
  addSmsContent,
  getTranscationSmsById,
  editSmsContent,
  getRestTransactionById,
  getResetNotificationById,
  getResetVoiceById,
  activeEmailTransaction,
  activeNotificationTranscation,
  activeVoiceTranscation,
  activeSmsTranscation,
  getRecruiters,
  getInterviewers,
  getCandidatesByJobId,
  getCommunicationLogsWithListView,
  getCommunicationLogsWithCardView,
  addOfferLetterTransaction,
  getOfferLetterTransaction,
  getOfferLetterTransactionById,
  updateOfferTransaction,
  getOfferTransactionTypes,
  getTranscationTemplateData,
  activeOfferLetterTranscation,
};
export default CommunicationService;
